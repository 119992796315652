<template>
    <div class="personalinfoContainerx">
        <div class="window">
            <img src="../../assets/info.png" class="windowImg"/>
            <div class="windowContent">
                <div class="itemLine">
                    <div class="item">Name:{{user.name}}</div>
                    <div class="item" v-if="user_type==='student'">School:{{user.account_name}}</div>
                </div>
                <div class="itemLine">
                    <div class="item">Email:{{user.email}}</div>
                    <div class="item" v-if="user_type==='student'">Subject:{{handleEventName(user.subject)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getUser,getEducator,getEmployee} from '../../api/index'
import {getUserId,getUserType,getProjectCode} from '../../utils/store'
export default {
    data(){
        return{
            user:{},
            user_type:getUserType()
        }
    },
    mounted(){
        this.getUser()
    },
    methods:{
        getUser(){
            if (getUserType() == 'educator'){
                getEducator(getUserId(),getUserType(),getProjectCode()).then(res=>{
                    if(res.data.code===0){
                        this.user = res.data.data
                    }
                })
            }
            if (getUserType() == 'student'){
                getUser(getUserId(),getUserType()).then(res=>{
                    if(res.data.code===0){
                        this.user = res.data.data
                    }
                })
            }
            if (getUserType() == 'employee'){
                getEmployee(getUserId()).then(res=>{
                    if(res.data.code===0){
                        this.user = res.data.data
                    }
                })
            }

            
        },
        handleEventName(event){
            if(event){
                let event_array = event.split(" ")
                return event_array[0]
            }else{
                return ''
            }
        }
    }
}
</script>

<style lang="less">
.personalinfoContainerx{
    width: 90%;
    .window{
        margin-top: 1vh;
        position: relative;
        .windowImg{

            width: 100%;
            height: 60vh;
            left: 20px;
            top:20px;
            // z-index: 1;
            position: absolute;
        }
        .windowContent{
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            // z-index: 2;
            .itemLine{
                display: flex;
                justify-content: space-between;
                width: 80%;
                margin: 5vh auto;
                .item{
                    width: 45%;
                    padding-bottom: 10px;
                    border-bottom: 2px solid #072D76;
                    color: #072D76;
                    font-size: 2.2vh;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                }
            }
           
        }
    }
}
</style>